import { setCookie } from './cookies';
import { User } from '@frontegg/redux-store/auth/interfaces';
import { ContextHolder } from '@frontegg/react';
import { clearCurrentTenant } from '../routes/ManageTenants/tenants';

export const login = (user: User) => {
    setCookie('fronteggSessionToken', user.accessToken);
}

export const logout = async (isLoggedIn: boolean=true) => {
    if (isLoggedIn) {
        await fetch('/api/access/register-logout');    
    }
    const baseUrl = ContextHolder.getContext().baseUrl;
    const redirectUrl = window.location.origin;
    clearCurrentTenant()
    document.cookie = "fronteggSessionToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${redirectUrl}`;
};