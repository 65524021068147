/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useState } from 'react';
import { useAuth, useLoginWithRedirect } from '@frontegg/react';
import { clarityInitialize, pendoInitialize } from '../external-tools';
import { setCurrentTenant, getCurrentTenant, TTenantDetails, getTenant } from '../routes/ManageTenants/tenants';
import { login, logout } from '../utils/fronteggAuth';

type IProps = {
    children: React.ReactNode;
};

const RootAuthWrapper: React.FC<IProps> = (props) => {
    const { children } = props;
    const { isAuthenticated, isLoading, user } = useAuth();

    const loginWithRedirect = useLoginWithRedirect();
    const [loggedIn, setLoggedIn] = useState<string | null>(localStorage.getItem("loggedIn"));

    useLayoutEffect((): void => {
        const setTenant = async () => {
            if (user === undefined || user === null) {
                logout(false)
                return;
            }

            const tenant: TTenantDetails | null = await getTenant(user);
            const tenantFromCookie: TTenantDetails | null = getCurrentTenant();
            if (tenant === null) {
                logout();
                return;
            } else if (tenant && tenantFromCookie !== tenant) {
                setCurrentTenant(tenant);
            }
            if (loggedIn === null) {
                await fetch('/api/access/register-login');
                localStorage.setItem("loggedIn", "");
                setLoggedIn("");
            }
        };

        if (user && isAuthenticated && !isLoading) {
            login(user);
            setTenant();
            if (process.env.NODE_ENV !== 'development') {
                pendoInitialize(user);
                clarityInitialize();
            }
        }
    }, [isAuthenticated, logout, user, isLoading, loggedIn]);

    if (isLoading || (loggedIn === null && isAuthenticated !== false)) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <div>Loading...</div>
            </div>
        );
    }

    if (!isAuthenticated) {
        loginWithRedirect()
        return null;
    }


    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default RootAuthWrapper;