import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExtensionIcon from '@mui/icons-material/Extension';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Logout from '@mui/icons-material/Logout';
import { Avatar, Collapse, Menu, MenuItem } from '@mui/material';
import { logout } from '../../utils/fronteggAuth';
import { useAuthUser } from '@frontegg/react';

import React, { useCallback, useState } from 'react';
import TerminalIcon from '@mui/icons-material/Terminal';
import PolicyIcon from '@mui/icons-material/Policy';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BuildIcon from '@mui/icons-material/Build';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

import logoClose from './prompt-security-website-favicon-color.png';
import logoOpen from './open_logo.svg'

import { clearCurrentTenant, getEnvTenants } from '../ManageTenants/tenants';
import { TenantSelection } from '../ManageTenants/TenantSelection';
import { useQuery } from '@tanstack/react-query';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerToggle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerBottom = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type NavigationItemProps = {
  text: string;
  open: boolean;
  isActive: boolean;
  icon: JSX.Element;
  children?: JSX.Element | null;
  collapsed?: boolean;
}


function NavigationItem({ text, open, collapsed, isActive, icon, children }: NavigationItemProps) {
  const collapsedStyle = collapsed && open ? { paddingLeft: 4.5 } : { px: 2.5 };

  return (
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        ...collapsedStyle
      }}
      selected={isActive}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
        {children}
    </ListItemButton>
  )
}

type RootProps = {
  userName: string;
}

export const Root = ({ userName }: RootProps) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [profileMenuAnchor, setProfileMenuAnchor] = useState<null | HTMLElement>(null);
  const profileMenuOpen = Boolean(profileMenuAnchor);
  const profileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchor(event.currentTarget);
  };
  const profileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  const handleLogout = useCallback(() => {
    // logout from the underlying Dashboards (former Kibana)
    fetch('/kibana/auth/logout', {
      method: 'POST',
      headers: {
        'osd-version': '2.11.0',
        'osd-xsrf': 'osd-fetch',
        'Content-Type': 'application/json'
      }
    })
    .catch((error) => {
      console.error('Error logging out from Kibana:', error);
    })
    .finally(() => {
      logout();
    });
  }, [logout]);

  const user = useAuthUser();
  const { data: envTenants } = useQuery(
    {
      queryKey: ["envTenants"],
      queryFn: async () => {
        return getEnvTenants(user);
      },
    }
  );

  const [manageCollapseOpen, setManageCollapseOpen] = useState(true);

  const handleClick3 = () => {
    setManageCollapseOpen(!manageCollapseOpen);
  };

  const userNameFirstLetter = userName.charAt(0);

  const navigationItems = [
    {
      text: "Dashboard",
      navigateTo: "/",
      icon: <DashboardIcon />
    },
    {
      text: "Extension",
      navigateTo: "/extension",
      icon: <ExtensionIcon />
    },
    {
      text: "Alerts",
      navigateTo: "/alerts",
      icon: <NotificationsIcon />
    },
    {
      text: "Logs",
      navigateTo: "/logs",
      icon: <TroubleshootIcon />
    },
    {
      text: "Audit",
      navigateTo: "/audit",
      icon: <VerifiedUserIcon />
    }
  ];

  const manageItems = [
    {
      text: "Connectors",
      navigateTo: "/manage/connectors",
      icon: <TerminalIcon />
    },
    {
      text: "Policies",
      navigateTo: "/manage/policies",
      icon: <PolicyIcon />
    },
    {
      text: "Configuration",
      navigateTo: "/manage/configuration",
      icon: <ListAltIcon />
    },
    {
      text: "Integrations",
      navigateTo: "/manage/integrations",
      icon: <SettingsEthernetIcon />
    }
  ];

  const logo = drawerOpen ? logoOpen : logoClose;

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={drawerOpen}>
        <DrawerHeader>
          <img src={logo} style={{width: '100%', paddingTop: '15px', paddingBottom: '15px'}} alt="Logo" />
        </DrawerHeader>
        <Divider />
        <List>
          {navigationItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <NavLink to={item.navigateTo}>
                {navLinkProps => <NavigationItem text={item.text} open={drawerOpen} icon={item.icon} {...navLinkProps} />}
              </NavLink>
            </ListItem>
          ))}
          <Divider />
          <ListItem key="Manage" disablePadding sx={{ display: 'block' }} onClick={handleClick3}>
            <NavigationItem text="Manage" open={drawerOpen} icon={<BuildIcon />} isActive={false}>
              {
                drawerOpen ? (manageCollapseOpen ? <ExpandLess /> : <ExpandMore />) : null
              }
            </NavigationItem>
          </ListItem>
          <Collapse in={manageCollapseOpen} timeout="auto" unmountOnExit>
            {manageItems.map((item) => (
              <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
                <NavLink to={item.navigateTo}>
                  {navLinkProps => <NavigationItem text={item.text} open={drawerOpen} icon={item.icon} {...navLinkProps} collapsed={manageCollapseOpen}/>}
                </NavLink>
              </ListItem>
            ))}
          </Collapse>
        </List>
        <DrawerBottom>
          <DrawerToggle>
            <IconButton onClick={handleDrawerToggle}>
              {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerToggle>
          <Divider />
          <List>
            {envTenants && <TenantSelection envTenants={envTenants}/>}
            <ListItem key="profile" disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: drawerOpen ? 'initial' : 'center',
                  px: 2.5,
                }} onClick={profileMenuClick}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>{userNameFirstLetter}</Avatar>
                </ListItemIcon>
                <ListItemText primary={userName} sx={{ opacity: drawerOpen ? 1 : 0 }} />
              </ListItemButton>
              <Menu
                anchorEl={profileMenuAnchor}
                open={profileMenuOpen}
                onClose={profileMenuClose}
                onClick={profileMenuClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </ListItem>
          </List>
        </DrawerBottom>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, display: 'flex', height: '100vh' }}>
        <Outlet />
      </Box>
    </Box>
  );
}
