/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { CatagoriesDescriptions, CatagoriesHeaders, connectorsToCatagories, HomeGrownApplicationsDefault, THomeGrownApplications, TProtectionCategories } from '../Protections';
import { FormProvider, useForm } from 'react-hook-form';
import { PSButton, PSError, PSFormControlLabel, Text } from '../../../ui-kit';
import { ProtectionKeyToComponentMap } from '../Protections/Common/protections_map_to_components';
import { Switch } from '@mui/material';
import { useRightDrawerContext } from '../../../contexts';
import { PromptResponsePlayground } from '../PromptResponsePlayground';

import { toast } from 'react-toastify';
import { getConnectorByName, getPolicyById, updatePolicyById } from '../ConnectorsCommon';
import { useGraphQL, useGraphQLMutation } from '../../../hooks';
import { HomegrownPoliciesApplicationsStyle } from './HomegrownApplicationsPolicies.css';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { deepMergeUniqueProperties } from '../../../utils';
import { createPortal } from 'react-dom';
import { useLocalStorage } from 'react-use';
import { fixDataEntityDataTypes } from '../Protections/Common/protections_fix_data_types_not_supported';

type IProps = {};

const homegrownApplicationsToCategory = connectorsToCatagories(HomeGrownApplicationsDefault);

const HomegrownApplicationsPolicies: React.FC<IProps> = (props) => {
    const { } = props;

    const { connectorName } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const policyIdSearchParams = searchParams.get('policyId');

    const removeSearchParam = (param: string) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(param);
        navigate({ ...location, search: searchParams.toString() }, { replace: true });
    };

    const { resetDrawer, initDrawer, wrapperDomNode } = useRightDrawerContext();
    const [formValues, setFormValues] = useState<THomeGrownApplications>({} as any);
    const homegrownApplicationsFormMethods = useForm<{ content: THomeGrownApplications }>({
        defaultValues: async () => {
            return {
                content: {
                    ...HomeGrownApplicationsDefault
                }
            }
        }
    })
    const { watch, control, formState, reset, getValues } = homegrownApplicationsFormMethods;
    watch();

    const [isShowAdvancedMode, setIsShowAdvancedMode] = useLocalStorage('isAdvanced', false);
    const [isAdvancedMode, setIsAdvancedMode] = useState(false);

    useEffect(() => {
        if (window.location.hash === '#isAdvanced=true') {
            setIsShowAdvancedMode(true);
            window.location.hash = '';
        } else if (window.location.hash === '#isAdvanced=false') {
            setIsShowAdvancedMode(false);
            window.location.hash = '';
        }
    }, [])

    useEffect(() => {
        initDrawer({
            width: 630,
            state: 'OPEN'
        });
        return () => resetDrawer();
    }, [])

    const savePolicyMutation = useGraphQLMutation({
        document: updatePolicyById,
        onSuccess: (response) => {
            reset({
                content: deepMergeUniqueProperties(response.updatePolicy.content, HomeGrownApplicationsDefault)
            });

            toast.success('Policy saved successfully');
        }
    })

    const getPoliciesIdsByApplicationId = useGraphQL({
        document: getConnectorByName,
        variables: {
            name: connectorName!
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchInterval: false,
        select: data => data.applications[0].policyIds[0],
        enabled: !policyIdSearchParams
    });

    const getPolicyQuery = useGraphQL({
        document: getPolicyById,
        variables: {
            policyId: policyIdSearchParams ?? getPoliciesIdsByApplicationId.data!
        },
        onSuccess: (data) => {
            const fixedContent = fixDataEntityDataTypes(data.policies[0].content);
            reset({
                content: deepMergeUniqueProperties(fixedContent, HomeGrownApplicationsDefault)
            });

            return {
                ...data.policies[0],
                appId: data.applications[0].id
            }
        },
        select: (data) => {
            return {
                ...data.policies[0],
                appId: data.applications[0].id
            }
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchInterval: false,
        enabled: policyIdSearchParams ? !!policyIdSearchParams : getPoliciesIdsByApplicationId.isSuccess
    });

    useEffect(() => {
        const subscription = watch(value => {
            setFormValues(value.content as THomeGrownApplications);
        })
        return () => subscription.unsubscribe();
    }, [watch])

    const resetPoliciesForm = () => {
        reset();
    }

    const handleSavePolicy = async () => {
        const { content } = getValues();
        await savePolicyMutation.mutateAsync({
            input: {
                id: getPolicyQuery.data!.id,
                content
            }
        });
    }

    const openOWASPInNewWindow = () => {
        window.open('https://genai.owasp.org/', '_blank');
    }

    if (getPolicyQuery.isRefetching || getPolicyQuery.isLoading) return null;

    if (getPolicyQuery.isError) return <PSError />

    return (
        <div css={HomegrownPoliciesApplicationsStyle.self}>

            {getPolicyQuery.isSuccess && createPortal(<PromptResponsePlayground
                appId={getPolicyQuery.data.appId}
                policy={formValues}
                policyName={getPolicyQuery.data.name}
                isAdvancedMode={isAdvancedMode}
                connectorType='homegrown'
            />, wrapperDomNode!)}

            {policyIdSearchParams &&
                <div css={HomegrownPoliciesApplicationsStyle.defaultPolicyContainer}>
                    <PSButton
                        variant='flat'
                        iconName='ArrowBackRounded'
                        onClick={() => removeSearchParam('policyId')}
                    >
                        Back to Manage Policies
                    </PSButton>
                    <Text variant='bold'>{getPolicyQuery.data?.name}</Text>
                </div>
            }

            <div css={HomegrownPoliciesApplicationsStyle.actionsHeaders}>
                <Text inline>Select which protections you’d like to apply to either prompts and/or responses (<Text inline onClick={openOWASPInNewWindow}>mapped to OWASP Top 10 for LLM Apps</Text>)</Text>

                {isShowAdvancedMode && <PSFormControlLabel
                    sx={{ marginLeft: 'auto' }}
                    value={isAdvancedMode}
                    onChange={() => setIsAdvancedMode(advancedMode => !advancedMode)}
                    control={<Switch />}
                    label="Advanced Mode"
                />}
            </div>

            <div css={HomegrownPoliciesApplicationsStyle.protectionsContainer}>
                <FormProvider {...homegrownApplicationsFormMethods}>
                    {Object.entries(homegrownApplicationsToCategory)
                        .sort(([currentCategory], [nextCategory]) => {
                            const order = ['security', 'data privacy', 'safety', 'general'];
                            return order.indexOf(currentCategory) - order.indexOf(nextCategory);
                        })
                        .map(([category, protections]) => {
                            return <div key={category} css={HomegrownPoliciesApplicationsStyle.catagoriesContainer}>
                                <div css={HomegrownPoliciesApplicationsStyle.categoryHeader}>
                                    <Text variant='header2'>{CatagoriesHeaders[category as TProtectionCategories]}</Text>
                                    <Text variant='small' color='black-70'>{CatagoriesDescriptions[category as TProtectionCategories]}</Text>
                                </div>
                                {[...protections].map((protection) => {
                                    return <React.Fragment key={protection}>
                                        {ProtectionKeyToComponentMap[protection]({ control, getValues, isAdvancedMode, connectorType: 'homegrown' })}
                                    </React.Fragment>
                                })}
                            </div>
                        })}
                </FormProvider>
            </div>

            <div css={HomegrownPoliciesApplicationsStyle.footer}>
                <div css={HomegrownPoliciesApplicationsStyle.saveCancelContainer}>
                    <PSButton variant='flat' disabled={!formState.isDirty} onClick={resetPoliciesForm} isLoading={savePolicyMutation.isPending}>Cancel</PSButton>
                    <PSButton variant='filled' disabled={!formState.isDirty || (Object.keys(formState.errors).length > 0) || formState.isValidating} onClick={handleSavePolicy} isLoading={savePolicyMutation.isPending}>Save</PSButton>
                </div>
            </div>
        </div>
    )
}

export default HomegrownApplicationsPolicies;