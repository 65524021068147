/** @jsxImportSource @emotion/react */
import { TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { PSButton, Text } from '../../../ui-kit';
import { TConnectorsTypes } from '../Protections';
import PlaygroundFileForm from './PlaygroundFileForm';
import { PlaygroundFileStyle } from './PlaygroundFileStyle.css';
import { getSanitizeFileJobStatus, JobStatusResponseSuccess, sanitizeFile, SanitizeFileRequest } from './sanitizeFile';

type IProps = {
    appId: string;
    policy: TConnectorsTypes
    policyName: string;
};

const PlaygroundFile: React.FC<IProps> = (props) => {
    const { appId, policy, policyName } = props;

    const sanitizeFileForm = useForm<Omit<SanitizeFileRequest, 'appId'>>();

    const performSanitizeTest = useMutation({
        mutationFn: async () => {
            const values = sanitizeFileForm.getValues();
            const response = await sanitizeFile({
                appId,
                policyData: policy,
                policyName,
                file: values.file,
            });

            let counter = 0;
            const jobResponse: JobStatusResponseSuccess = await new Promise((resolve, reject) => {
                const interval = setInterval(async () => {
                    try {
                        const jobResponse = await getSanitizeFileJobStatus({
                            appId,
                            jobId: response.jobId
                        });

                        if (++counter > 60) {
                            clearInterval(interval);
                            reject(new Error('Sanitize job timeout'));
                            return;
                        }

                        if (['in progress', 'created'].includes(jobResponse.status)) return;

                        clearInterval(interval);

                        switch (jobResponse.status) {
                            case 'failed':
                                reject(new Error('Sanitize job failed'));
                                break;
                            case 'done':
                                resolve(jobResponse);
                                break;
                            default:
                                reject(new Error('Unexpected job status'));
                        }
                    } catch (error) {
                        clearInterval(interval);
                        reject(new Error('Sanitize job failed'))
                    }
                }, 1000);
            });

            return jobResponse;
        }
    })


    return (
        <React.Fragment>
            <div css={PlaygroundFileStyle.formContainer}>
                <PlaygroundFileForm
                    formModel={sanitizeFileForm}
                    isLoading={performSanitizeTest.isPending}
                />

                <PSButton
                    variant='filled'
                    disabled={!sanitizeFileForm.getValues('file')}
                    css={PlaygroundFileStyle.testButton}
                    isLoading={performSanitizeTest.isPending}
                    onClick={() => performSanitizeTest.mutateAsync()}
                >
                    Test
                </PSButton>
            </div>

            <div>
                {
                    !performSanitizeTest.isPending
                    && performSanitizeTest.isSuccess
                    && performSanitizeTest.data.status === 'done'
                    && <div css={PlaygroundFileStyle.resultContainer}>
                        <Text inline variant="bold">Results</Text>
                        <TextField css={PlaygroundFileStyle.sanitizedFileOutputTextField}
                            fullWidth
                            multiline
                            disabled
                            value={performSanitizeTest.data.content}
                        />
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default PlaygroundFile;