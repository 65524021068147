import { TConnectorsTypes } from "../Protections";

export type SanitizeFileRequest = {
    file: File | null;
    policyData: TConnectorsTypes;
    policyName: string;
    appId: string;
}

type SanitizeFileResponse = {
    jobId: string;
}

export const sanitizeFile = async (request: SanitizeFileRequest): Promise<SanitizeFileResponse> => {
    const { file, policyData, appId, policyName } = request;
    const formData = new FormData();
    formData.append("file", file!);
    formData.append("policy", JSON.stringify(policyData));
    formData.append("policyName", policyName);

    const response = await fetch('/api/sanitizeFile', {
        method: 'POST',
        headers: {
            'App-Id': appId,
        },
        body: formData
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
}

export type JobStatusRequest = {
    jobId: string,
    appId: string
}

export type JobStatusResponseSuccess = {
    status: 'done';
    content: string;
}

export type JobStatusResponseInProgress = {
    status: 'in progress' | 'failed' | 'created';
}

export type JobStatusResponse = JobStatusResponseSuccess | JobStatusResponseInProgress;

export const getSanitizeFileJobStatus = async (request: JobStatusRequest): Promise<JobStatusResponse> => {
    const { appId, jobId } = request;
    const response = await fetch(`/api/sanitizeFile?jobId=${jobId}`, {
        method: 'GET',
        headers: {
            'App-Id': appId,
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jobStatusResponse: JobStatusResponse = await response.json();
    return jobStatusResponse;
}